import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout/layout"
import favicon from "../images/favicon.ico"

import ordermanagementimg from "../images/marketing/svg/order-management/order-management-hero.svg"
import paperlessimg from "../images/marketing/svg/order-management/paperless.svg"
import humanerrorimg from "../images/marketing/svg/order-management/human-error.svg"
import ontimeimg from "../images/marketing/svg/order-management/on-time.svg"
import diagramimg from "../images/marketing/svg/order-management/diagram-illus.svg"
import macbookimg from "../images/marketing/svg/order-management/macbook-illus.png"
import mapimg from "../images/marketing/svg/order-management/map-illus.svg"

export default () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>
        Order Management - EzriRx.com - Marketplace Platform for 65,000+
        Pharmaceuticals
      </title>
      <meta
        name="keywords"
        content="ezrirx, order management, pharmacy purchasing platform"
      />
      <meta
        name="description"
        content="Manage your orders with ease. EzriRx’s order management system is simple and user-friendly to ensure that you are able to keep track of your orders without wasting time."
      />
      <link rel="canonical" href="https://www.ezrirx.com/pharmacy-order-management/" />
      <link rel="shortcut icon" type="image/png" href={`${favicon}`} />
    </Helmet>
    <Layout>
      <div className="body">
        <section className="hero-banner" id="hero-order-management">
          <div className="contents">
            <div className="hero-banner__inner">
              <div className="hero-content">
                <h1>Manage your orders with ease</h1>
                <p>
                  EzriRx’s order management system is simple and user-friendly
                  to ensure that you are able to keep track of your orders
                  without wasting time.
                </p>
              </div>
              <div className="hero-image">
                <img
                  src={ordermanagementimg}
                  alt="Manage your pharmacy order easily and instantly."
                ></img>
              </div>
            </div>
          </div>
        </section>

        <section className="introduction-wrapper">
          <div className="contents">
            <div className="introduction-box move-less">
              <div className="title-box text-center">
                <h2>Discover the new way</h2>
                <p>
                  Instead of messy paperwork and manual shipment tracking,
                  EzriRx takes care of all of this for you in one simple
                  package.
                </p>
              </div>

              <div className="box-3cols clearfix">
                <div className="col col--intro">
                  <img
                    src={paperlessimg}
                    alt="Goodbye Paperwork on pharmacy ordering"
                  ></img>
                  <div className="col--intro__content">
                    <h4>Goodbye Paperwork</h4>
                    <p>
                      Your orders and invoices are all available on your EzriRx
                      account, accessible at a moment’s notice. Goodbye stacks
                      of paperwork, hello organization and peace of mind!
                    </p>
                  </div>
                </div>
                <div className="col col--intro">
                  <img
                    src={humanerrorimg}
                    alt="Minimize human error in the pharmacy ordering process"
                  ></img>
                  <div className="col--intro__content">
                    <h4>Minimize human error</h4>
                    <p>
                      When your records are in the same place where you made
                      your orders, the chance of clerical errors occurring is
                      reduced to nil. It just makes better sense.
                    </p>
                  </div>
                </div>
                <div className="col col--intro">
                  <img
                    src={ontimeimg}
                    alt="Get your pharmacy orders on time"
                  ></img>
                  <div className="col--intro__content">
                    <h4>Get your orders on time</h4>
                    <p>
                      Instead of having to track down shipments, EzriRx offers a
                      built-in shipment tracking feature. See exactly where your
                      order is and when it will arrive, all at the click of a
                      button.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="marketing-content with-border">
          <div className="contents">
            <div className="col single-col">
              <div className="title-box">
                <h2>How it all works</h2>
              </div>
              <p>
                With EzriRx’s state-of-the-art technology, ordering
                pharmaceuticals takes a fraction of the time it used to. By
                ridding of the need for paperwork and phone calls, EzriRx has
                revolutionized your ordering process. With just a few clicks and
                a quick payment, your pharmaceuticals are on their way to your
                store!
              </p>
            </div>

            <div className="table-2cols with-padding-top">
              <div className="col col--text text-left-mobile">
                <div className="title-box">
                  <h2>Submitting an order</h2>
                </div>
                <p>
                  EzriRx allows you to search for what you’re looking for and
                  submit orders with incredible ease.
                </p>
                <ul className="checklists">
                  <li>
                    <h4>Search and add to cart</h4>
                    <p>
                      Simply search for products by name or NDC and add to cart
                      in one click.
                    </p>
                  </li>
                  <li>
                    <h4>Select a payment method</h4>
                    <p>
                      As the only online pharmaceutical purchasing platform that
                      facilitates all aspects of payment processing, EzriRx
                      makes ordering even easier. Upon checkout you are given
                      the following 3 safe and secure methods of payment:
                    </p>
                    <ol>
                      <li>
                        <p>
                          Credit - pay using credit given to you by the
                          wholesaler
                        </p>
                      </li>
                      <li>
                        <p>ACH transfer - pay using your bank account</p>
                      </li>
                      <li>
                        <p>Credit card - pay using your credit card</p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>Process the order</h4>
                    <p>
                      Simply click the “Place order” button and you’re all set!
                      You will be redirected to a page where you can review your
                      order and make any changes necessary.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="col col--img text-center">
                <img src={diagramimg} alt="Submitting an order"></img>
              </div>
            </div>
          </div>
        </section>

        <section className="marketing-content with-border">
          <div className="contents">
            <div className="table-2cols table-2cols--mobile">
              <div className="col col--img text-center">
                <img src={macbookimg} alt="Manage your pharmacy order"></img>
              </div>
              <div className="col col--text text-left-mobile">
                <div className="title-box">
                  <h2>Manage your orders</h2>
                </div>
                <p>
                  With EzriRx, all your pharmaceutical purchases are in one
                  place with complete control to edit, cancel, and more.
                </p>
                <ul className="checklists" style={{ paddingBottom: "20px" }}>
                  <li>
                    <h4>Edit, cancel, and return</h4>
                    <p>
                      You can change quantities, remove products, and cancel
                      orders at any time up until the wholesaler fulfills your
                      order. And if you’ve received it and are not satisfied,
                      you can submit a return - all through EzriRx.
                    </p>
                  </li>
                  <li>
                    <h4>Sort, filter, and find orders</h4>
                    <p>
                      You can search for your orders, and apply filters and
                      sorts to find exactly what you’re looking for.
                    </p>
                  </li>
                  <li>
                    <h4>Favorite orders</h4>
                    <p>
                      If you have specific products you purchase frequently, you
                      can mark these orders as “favorite” and view a list of
                      your favorite orders at any time.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="marketing-content with-cornflower-blue-bg">
          <div className="contents">
            <div className="table-2cols table-2cols">
              <div className="col col--text text-left-mobile">
                <div className="title-box">
                  <h2>Order delivery</h2>
                </div>
                <p>
                  Always know exactly where your order is with EzriRx’s
                  easy-to-use shipment tracking features.
                </p>
                <ul className="checklists">
                  <li>
                    <h4>Fulfillment notifications</h4>
                    <p>
                      You will be notified via email the moment your order is
                      fulfilled by the wholesaler, so you won’t be left to
                      wonder when your order is going to arrive.
                    </p>
                  </li>
                  <li>
                    <h4>Shipment updates</h4>
                    <p>
                      As your order finds its way to you, you will receive
                      updates via email for each status update on your shipment.
                      EzriRx does the tracking for you so you don’t have to.
                    </p>
                  </li>
                  <li>
                    <h4>Packing slips</h4>
                    <p>
                      Quickly access your packing slips at any time by viewing
                      your order, or by browsing your documents.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="col col--img text-center">
                <img
                  src={mapimg}
                  alt="Order delivery"
                  className="img-full"
                ></img>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  </>
)
